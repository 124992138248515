var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{directives:[{name:"page-access",rawName:"v-page-access",value:(_vm.permissions.promoCreate),expression:"permissions.promoCreate"}]},[_c('CCol',{attrs:{"md":"10","sm":"10"}},[_c('CCard',[_c('CCardHeader',{staticClass:"pt-4"},[_c('h5',[_vm._v(_vm._s(_vm.$t("PROMOS.CREATE_HEADER")))])]),_c('CCardBody',[_c('ValidationObserver',{ref:"validationObserver"},[_c('CForm',[_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.PROMO_TYPE")))]),_c('ValidationProvider',{ref:"Promo Type",staticClass:"form-group col-6",attrs:{"name":"Promo Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"col-12 mb-0",attrs:{"id":"promoType","name":"promoType","type":"text","disabled":""},model:{value:(_vm.promo.type),callback:function ($$v) {_vm.$set(_vm.promo, "type", $$v)},expression:"promo.type"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.CODE")))]),_c('ValidationProvider',{ref:"Promo Label",staticClass:"form-group col-6",attrs:{"name":"Promo Label","rules":"minmax:6,25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"col-12 mb-0",attrs:{"id":"promolabel","name":"promolabel","type":"text"},on:{"blur":function($event){return _vm.verifyPromoLabel(_vm.promo.label)}},model:{value:(_vm.promo.label),callback:function ($$v) {_vm.$set(_vm.promo, "label", $$v)},expression:"promo.label"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.CURRENCY")))]),_c('span',{staticClass:"form-group col-sm-9"},[_c('ValidationProvider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.currencyOptions(),"disabled":""},model:{value:(_vm.promo.currency),callback:function ($$v) {_vm.$set(_vm.promo, "currency", $$v)},expression:"promo.currency"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPromoChannel),expression:"showPromoChannel"}]},[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.CHANNELS")))]),_c('ValidationProvider',{staticClass:"form-group col-6 p-2",attrs:{"name":"Promo Channels","rules":("requiredPromoChan:" + _vm.showPromoChannel)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{staticClass:"col-12",attrs:{"options":_vm.options,"value-field":"id","text-field":"name","disabled-field":"notEnabled"},model:{value:(_vm.promoChannel),callback:function ($$v) {_vm.promoChannel=$$v},expression:"promoChannel"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('CRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBankList),expression:"showBankList"}]},[_c('CContainer',{staticClass:"p-2 mx-3 border border-light rounded"},[_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.BANK")))]),_c('ValidationProvider',{staticClass:"col-sm-9",attrs:{"name":"Bank","rules":("requiredBank:" + _vm.showBankList)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.banks},model:{value:(_vm.selectedBank),callback:function ($$v) {_vm.selectedBank=$$v},expression:"selectedBank"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('CRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBINList),expression:"showBINList"}]},[_c('CContainer',{staticClass:"p-2 m-3 border border-light rounded"},[_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.BIN")))]),_c('ValidationProvider',{staticClass:"col-sm-9",attrs:{"name":"BIN Number","rules":("requiredBIN:" + _vm.showBINList)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Enter 6 digits (,) seperated bin numbers","rows":"5"},model:{value:(_vm.BINNumbers),callback:function ($$v) {_vm.BINNumbers=$$v},expression:"BINNumbers"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.SUBTYPE")))]),_c('span',{staticClass:"form-group col-sm-9"},[_c('ValidationProvider',{attrs:{"name":"Promo Sub Type","rules":"requiredPromoType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.sub_type_options,"multiple":false,"track-by":"value","label":"label","disabled":_vm.disable_subtype,"placeholder":_vm.$t('PROMOS.PLACEHOLDER.SUBTYPE')},model:{value:(_vm.promo.sub_type),callback:function ($$v) {_vm.$set(_vm.promo, "sub_type", $$v)},expression:"promo.sub_type"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.TYPE")))]),_c('ValidationProvider',{staticClass:"col-sm-9",attrs:{"name":"Discount Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-radio',{attrs:{"name":"discountP-radios","value":"percentage"},model:{value:(_vm.promo.discount_type),callback:function ($$v) {_vm.$set(_vm.promo, "discount_type", $$v)},expression:"promo.discount_type"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.PERCENTAGE")))]),_c('b-form-radio',{attrs:{"name":"discountF-radios","value":"flat"},model:{value:(_vm.promo.discount_type),callback:function ($$v) {_vm.$set(_vm.promo, "discount_type", $$v)},expression:"promo.discount_type"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.AMOUNT")))])],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('CRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPayMethodsEWallet),expression:"showPayMethodsEWallet"}]},[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.PAY_METHODS")))]),_c('span',{staticClass:"form-group col-sm-9"},[_c('ValidationProvider',{attrs:{"name":"Payment Methods","rules":("requiredPayMethods:" + _vm.showPayMethodsEWallet)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.getPaymentMethodsByCategory(),"multiple":true,"placeholder":_vm.$t('PROMOS.PLACEHOLDER.EWALLETS'),"track-by":"unique_id","label":"name"},on:{"select":_vm.updatePaymentMethods},model:{value:(_vm.selectedPaymentMethods),callback:function ($$v) {_vm.selectedPaymentMethods=$$v},expression:"selectedPaymentMethods"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPayMethodsVA),expression:"showPayMethodsVA"}]},[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.PAY_METHODS")))]),_c('span',{staticClass:"form-group col-sm-9"},[_c('ValidationProvider',{attrs:{"name":"Payment Methods","rules":("requiredPayMethods:" + _vm.showPayMethodsVA)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.getPaymentMethodsByCategory(),"multiple":true,"placeholder":_vm.$t('PROMOS.PLACEHOLDER.VA'),"track-by":"unique_id","label":"name"},on:{"select":_vm.updatePaymentMethods},model:{value:(_vm.selectedPaymentMethods),callback:function ($$v) {_vm.selectedPaymentMethods=$$v},expression:"selectedPaymentMethods"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CContainer',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAmountDiscount),expression:"showAmountDiscount"}],staticClass:"p-2 mb-3 border border-light rounded"},[_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.DISCOUNT")))]),_c('ValidationProvider',{staticClass:"col-sm-3",attrs:{"name":"Discount Amount","rules":("requiredAmt:" + _vm.showAmountDiscount)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"md","append":"IDR"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"IDR","horizontal":""},model:{value:(_vm.promo.discount),callback:function ($$v) {_vm.$set(_vm.promo, "discount", $$v)},expression:"promo.discount"}})],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"col-sm-6",attrs:{"name":"Min. purchase Amount","rules":("maxDiscountAmt:" + (_vm.promo.discount))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"md","prepend":"Min Purchase"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Min. purchase IDR (optional)","horizontal":""},model:{value:(_vm.promo.min_order_amount),callback:function ($$v) {_vm.$set(_vm.promo, "min_order_amount", $$v)},expression:"promo.min_order_amount"}})],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CContainer',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPercentageDiscount),expression:"showPercentageDiscount"}],staticClass:"p-2 mb-3 border border-light rounded"},[_c('CRow',{staticClass:"mb-2"},[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.DISCOUNT_P")))]),_c('ValidationProvider',{staticClass:"col-sm-9",attrs:{"name":"Discount Percentage","rules":("minmaxPercentage:1,100," + _vm.showPercentageDiscount)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"col-3 px-0",attrs:{"size":"md","append":"%"}},[_c('b-form-input',{attrs:{"min":"1","max":"100","type":"text","placeholder":"%"},model:{value:(_vm.promo.discount),callback:function ($$v) {_vm.$set(_vm.promo, "discount", $$v)},expression:"promo.discount"}})],1),_c('span',{staticClass:"text-danger col-9 px-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('CRow',{staticClass:"mb-2"},[_c('div',{staticClass:"col-sm-3"}),_c('ValidationProvider',{staticClass:"col-sm-9",attrs:{"name":"Min Order","rules":"onlyNumbers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"md","prepend":"Min Purchase"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Min. purchase IDR (optional)","horizontal":""},model:{value:(_vm.promo.min_order_amount),callback:function ($$v) {_vm.$set(_vm.promo, "min_order_amount", $$v)},expression:"promo.min_order_amount"}})],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('CRow',[_c('div',{staticClass:"col-sm-3"}),_c('ValidationProvider',{staticClass:"col-sm-9",attrs:{"name":"Max Discount","rules":("maxDiscount:" + (_vm.promo.min_order_amount))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"md","prepend":"Max Discount"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Max. discount IDR (optional)","horizontal":""},model:{value:(_vm.promo.max_discount_amount),callback:function ($$v) {_vm.$set(_vm.promo, "max_discount_amount", $$v)},expression:"promo.max_discount_amount"}})],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.DEDUCTION_TYPE")))]),_c('span',{staticClass:"form-group col-sm-9"},[_c('ValidationProvider',{attrs:{"name":"Deduction Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.deduction_type,"disabled":""},model:{value:(_vm.promo.price_deduction_type),callback:function ($$v) {_vm.$set(_vm.promo, "price_deduction_type", $$v)},expression:"promo.price_deduction_type"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.LIMIT_TYPE")))]),_c('span',{staticClass:"form-group col-sm-9"},[_c('ValidationProvider',{attrs:{"name":"Promo Limit Type","rules":"requiredPromoType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.limit_type_options,"multiple":false,"track-by":"value","label":"label","disabled":_vm.disable_limit,"placeholder":_vm.$t('PROMOS.PLACEHOLDER.LIMIT')},model:{value:(_vm.promo.limit_type),callback:function ($$v) {_vm.$set(_vm.promo, "limit_type", $$v)},expression:"promo.limit_type"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.LIMIT_VALUE")))]),_c('ValidationProvider',{staticClass:"form-group col-6",attrs:{"name":"Limit","rules":"requiredOnlyNumbers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"col-12 mb-0",attrs:{"type":"text","placeholder":"Limit Value","horizontal":""},model:{value:(_vm.promo.limit_value),callback:function ($$v) {_vm.$set(_vm.promo, "limit_value", $$v)},expression:"promo.limit_value"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('CRow',[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.FROM")))]),_c('ValidationProvider',{staticClass:"col-sm-6",attrs:{"name":"Effective From","rules":"requiredDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"min":_vm.getTodayDate(),"id":"startDate","type":"date","onkeydown":"return false"},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"col-sm-3",attrs:{"name":"StartTime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-timepicker',{attrs:{"id":"startTime","format":"HH:mm"},model:{value:(_vm.start_time),callback:function ($$v) {_vm.start_time=$$v},expression:"start_time"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('CRow',{staticClass:"mt-3"},[_c('CCol',{staticClass:"col-form-label",attrs:{"tag":"label","sm":"3"}},[_vm._v(_vm._s(_vm.$t("PROMOS.LABELS.TO")))]),_c('ValidationProvider',{staticClass:"col-sm-6",attrs:{"name":"Effective To","rules":"requiredDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"min":_vm.getTodayDate(),"id":"endDate","type":"date","onkeydown":"return false"},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"col-sm-3",attrs:{"name":"EndTime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-timepicker',{attrs:{"id":"endTime","format":"HH:mm"},model:{value:(_vm.end_time),callback:function ($$v) {_vm.end_time=$$v},expression:"end_time"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('CCardFooter',[_c('CRow',{staticClass:"float-left"},[_c('CCol',{staticClass:"text-center",attrs:{"sm":""}},[_c('CButton',{attrs:{"color":"durianprimary"},on:{"click":function($event){$event.preventDefault();return _vm.validatePromo($event)}}},[_c('CIcon',{attrs:{"name":"cil-check-circle"}}),_vm._v(" "+_vm._s(_vm.$t("PROMOS.SUBMIT"))+" ")],1),_c('CButton',{on:{"click":_vm.goBack}},[_vm._v(_vm._s(_vm.$t("COMMON.BACK_BUTTON")))])],1)],1)],1)],1)],1),_c('CModal',{attrs:{"title":_vm.$t('PROMOS.SETTINGS'),"color":"light","size":"lg","no-close-on-backdrop":false,"centered":true,"show":_vm.showSettingsModal},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"primary"},on:{"click":_vm.checkPromoType}},[_vm._v(_vm._s(_vm.$t("COMMON.NEXT")))])]},proxy:true}])},[_c('CContainer',[_c('ValidationObserver',{ref:"validationObserverModal"},[_c('CForm',[_c('CRow',[_c('CCol',[_c('ValidationProvider',{attrs:{"name":"Promo","rules":"requiredPromoType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{staticClass:"mx-3",attrs:{"options":_vm.promo_types,"multiple":false,"placeholder":_vm.$t('PROMOS.TYPES.SELECT'),"track-by":"type","label":"label","allow-empty":false},on:{"select":_vm.updatePromoSettings},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('span',{staticClass:"text-danger mx-3"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('CModal',{attrs:{"title":_vm.$t('PROMOS.CREATE_CONFIRMATION_TITLE'),"color":"light","no-close-on-backdrop":false,"centered":true,"show":_vm.showConfirmationModal},on:{"update:show":function($event){_vm.showConfirmationModal=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.formatPromoData()}}},[_vm._v(_vm._s(_vm.$t("COMMON.CONTINUE")))]),_c('CButton',{attrs:{"color":"lightborder"},on:{"click":function($event){_vm.showConfirmationModal = false}}},[_vm._v(_vm._s(_vm.$t("COMMON.CANCEL")))])]},proxy:true}])},[_c('CContainer',[_c('label',{staticClass:"modal-msg"},[_vm._v(_vm._s(_vm.$t("PROMOS.CREATE_CONFIRMATION_MSG")))])])],1),_c('CModal',{attrs:{"title":_vm.$t('PROMOS.DUPLICATE_ALERT_TITLE'),"color":"danger","no-close-on-backdrop":false,"centered":true,"show":_vm.showDuplicatePromoAlert},on:{"update:show":function($event){_vm.showDuplicatePromoAlert=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"durianprimary"},on:{"click":_vm.clearPromoLabel}},[_vm._v(_vm._s(_vm.$t("COMMON.OK")))])]},proxy:true}])},[_c('CContainer',[_c('label',{staticClass:"modal-msg"},[_vm._v(_vm._s(_vm.$t("PROMOS.DUPLICATE_PROMO_ALERT")))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }