<template>
  <CRow v-page-access="permissions.promoCreate">
    <CCol md="10" sm="10">
      <CCard>
        <CCardHeader class="pt-4">
          <h5>{{ $t("PROMOS.CREATE_HEADER") }}</h5>
        </CCardHeader>
        <CCardBody>
          <ValidationObserver ref="validationObserver">
            <CForm>
              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.PROMO_TYPE") }}</CCol>
                <ValidationProvider
                  name="Promo Type"
                  ref="Promo Type"
                  rules="required"
                  v-slot="{ errors }"
                  class="form-group col-6"
                >
                  <b-form-input
                    class="col-12 mb-0"
                    id="promoType"
                    name="promoType"
                    v-model="promo.type"
                    type="text"
                    disabled
                  ></b-form-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>
              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.CODE") }}</CCol>
                <ValidationProvider
                  name="Promo Label"
                  ref="Promo Label"
                  rules="minmax:6,25"
                  v-slot="{ errors }"
                  class="form-group col-6"
                >
                  <b-form-input
                    class="col-12 mb-0"
                    id="promolabel"
                    name="promolabel"
                    v-model="promo.label"
                    type="text"
                    @blur="verifyPromoLabel(promo.label)"
                  ></b-form-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>
              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.CURRENCY") }}</CCol>
                <span class="form-group col-sm-9">
                  <ValidationProvider name="Currency" :rules="`required`" v-slot="{ errors }">
                    <b-form-select v-model="promo.currency" :options="currencyOptions()" disabled></b-form-select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </CRow>

              <CRow v-show="showPromoChannel">
                <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.CHANNELS") }}</CCol>
                <ValidationProvider
                  name="Promo Channels"
                  :rules="`requiredPromoChan:${showPromoChannel}`"
                  v-slot="{ errors }"
                  class="form-group col-6 p-2"
                >
                  <b-form-checkbox-group
                    v-model="promoChannel"
                    :options="options"
                    class="col-12"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                  ></b-form-checkbox-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>

              <CRow v-show="showBankList">
                <CContainer class="p-2 mx-3 border border-light rounded">
                  <CRow>
                    <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.BANK") }}</CCol>
                    <ValidationProvider
                      name="Bank"
                      :rules="`requiredBank:${showBankList}`"
                      v-slot="{ errors }"
                      class="col-sm-9"
                    >
                      <b-form-select v-model="selectedBank" :options="banks"></b-form-select>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </CRow>
                </CContainer>
              </CRow>
              <CRow v-show="showBINList">
                <CContainer class="p-2 m-3 border border-light rounded">
                  <CRow>
                    <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.BIN") }}</CCol>
                    <ValidationProvider
                      name="BIN Number"
                      :rules="`requiredBIN:${showBINList}`"
                      v-slot="{ errors }"
                      class="col-sm-9"
                    >
                      <b-form-textarea
                        v-model="BINNumbers"
                        placeholder="Enter 6 digits (,) seperated bin numbers"
                        rows="5"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </CRow>
                </CContainer>
              </CRow>
              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.SUBTYPE") }}</CCol>
                <span class="form-group col-sm-9">
                  <ValidationProvider
                    name="Promo Sub Type"
                    rules="requiredPromoType"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      v-model="promo.sub_type"
                      :options="sub_type_options"
                      :multiple="false"
                      track-by="value"
                      label="label"
                      :disabled="disable_subtype"
                      :placeholder="$t('PROMOS.PLACEHOLDER.SUBTYPE')"
                    ></multiselect>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </CRow>
              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.TYPE") }}</CCol>
                <ValidationProvider
                  name="Discount Type"
                  rules="required"
                  v-slot="{ errors }"
                  class="col-sm-9"
                >
                  <b-form-group>
                    <b-form-radio
                      v-model="promo.discount_type"
                      name="discountP-radios"
                      value="percentage"
                    >{{ $t("PROMOS.LABELS.PERCENTAGE") }}</b-form-radio>
                    <b-form-radio
                      v-model="promo.discount_type"
                      name="discountF-radios"
                      value="flat"
                    >{{ $t("PROMOS.LABELS.AMOUNT") }}</b-form-radio>
                  </b-form-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>
              <CRow v-show="showPayMethodsEWallet">
                <CCol
                  tag="label"
                  sm="3"
                  class="col-form-label"
                >{{ $t("PROMOS.LABELS.PAY_METHODS") }}</CCol>
                <span class="form-group col-sm-9">
                  <ValidationProvider
                    name="Payment Methods"
                    :rules="`requiredPayMethods:${showPayMethodsEWallet}`"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      v-model="selectedPaymentMethods"
                      :options="getPaymentMethodsByCategory()"
                      :multiple="true"
                      :placeholder="$t('PROMOS.PLACEHOLDER.EWALLETS')"
                      track-by="unique_id"
                      label="name"
                      @select="updatePaymentMethods"
                    ></multiselect>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </CRow>
              <CRow v-show="showPayMethodsVA">
                <CCol
                  tag="label"
                  sm="3"
                  class="col-form-label"
                >{{ $t("PROMOS.LABELS.PAY_METHODS") }}</CCol>
                <span class="form-group col-sm-9">
                  <ValidationProvider
                    name="Payment Methods"
                    :rules="`requiredPayMethods:${showPayMethodsVA}`"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      v-model="selectedPaymentMethods"
                      :options="getPaymentMethodsByCategory()"
                      :multiple="true"
                      :placeholder="$t('PROMOS.PLACEHOLDER.VA')"
                      track-by="unique_id"
                      label="name"
                      @select="updatePaymentMethods"
                    ></multiselect>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </CRow>
              <CContainer v-show="showAmountDiscount" class="p-2 mb-3 border border-light rounded">
                <CRow>
                  <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.DISCOUNT") }}</CCol>
                  <ValidationProvider
                    name="Discount Amount"
                    :rules="`requiredAmt:${showAmountDiscount}`"
                    v-slot="{ errors }"
                    class="col-sm-3"
                  >
                    <b-input-group size="md" append="IDR">
                      <b-form-input
                        type="text"
                        v-model="promo.discount"
                        placeholder="IDR"
                        horizontal
                      />
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Min. purchase Amount"
                    :rules="`maxDiscountAmt:${promo.discount}`"
                    v-slot="{ errors }"
                    class="col-sm-6"
                  >
                    <b-input-group size="md" prepend="Min Purchase">
                      <b-form-input
                        type="text"
                        v-model="promo.min_order_amount"
                        placeholder="Min. purchase IDR (optional)"
                        horizontal
                      />
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </CRow>
              </CContainer>
              <CContainer
                v-show="showPercentageDiscount"
                class="p-2 mb-3 border border-light rounded"
              >
                <CRow class="mb-2">
                  <CCol
                    tag="label"
                    sm="3"
                    class="col-form-label"
                  >{{ $t("PROMOS.LABELS.DISCOUNT_P") }}</CCol>
                  <ValidationProvider
                    name="Discount Percentage"
                    :rules="`minmaxPercentage:1,100,${showPercentageDiscount}`"
                    v-slot="{ errors }"
                    class="col-sm-9"
                  >
                    <b-input-group size="md" append="%" class="col-3 px-0">
                      <b-form-input
                        min="1"
                        max="100"
                        type="text"
                        v-model="promo.discount"
                        placeholder="%"
                      />
                    </b-input-group>
                    <span class="text-danger col-9 px-0">{{ errors[0] }}</span>
                  </ValidationProvider>
                </CRow>
                <CRow class="mb-2">
                  <div class="col-sm-3" />
                  <ValidationProvider
                    name="Min Order"
                    rules="onlyNumbers"
                    v-slot="{ errors }"
                    class="col-sm-9"
                  >
                    <b-input-group size="md" prepend="Min Purchase">
                      <b-form-input
                        v-model="promo.min_order_amount"
                        type="text"
                        placeholder="Min. purchase IDR (optional)"
                        horizontal
                      />
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </CRow>
                <CRow>
                  <div class="col-sm-3" />
                  <ValidationProvider
                    name="Max Discount"
                    :rules="`maxDiscount:${promo.min_order_amount}`"
                    v-slot="{ errors }"
                    class="col-sm-9"
                  >
                    <b-input-group size="md" prepend="Max Discount">
                      <b-form-input
                        type="text"
                        v-model="promo.max_discount_amount"
                        placeholder="Max. discount IDR (optional)"
                        horizontal
                      />
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </CRow>
              </CContainer>
              <CRow>
                <CCol
                  tag="label"
                  sm="3"
                  class="col-form-label"
                >{{ $t("PROMOS.LABELS.DEDUCTION_TYPE") }}</CCol>
                <span class="form-group col-sm-9">
                  <ValidationProvider name="Deduction Type" :rules="`required`" v-slot="{ errors }">
                    <b-form-select
                      v-model="promo.price_deduction_type"
                      :options="deduction_type"
                      disabled
                    ></b-form-select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </CRow>
              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.LIMIT_TYPE") }}</CCol>
                <span class="form-group col-sm-9">
                  <ValidationProvider
                    name="Promo Limit Type"
                    rules="requiredPromoType"
                    v-slot="{ errors }"
                  >
                    <multiselect
                      v-model="promo.limit_type"
                      :options="limit_type_options"
                      :multiple="false"
                      track-by="value"
                      label="label"
                      :disabled="disable_limit"
                      :placeholder="$t('PROMOS.PLACEHOLDER.LIMIT')"
                    ></multiselect>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </CRow>
              <CRow>
                <CCol
                  tag="label"
                  sm="3"
                  class="col-form-label"
                >{{ $t("PROMOS.LABELS.LIMIT_VALUE") }}</CCol>
                <ValidationProvider
                  name="Limit"
                  rules="requiredOnlyNumbers"
                  v-slot="{ errors }"
                  class="form-group col-6"
                >
                  <b-form-input
                    class="col-12 mb-0"
                    type="text"
                    v-model="promo.limit_value"
                    placeholder="Limit Value"
                    horizontal
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>
              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.FROM") }}</CCol>
                <ValidationProvider
                  name="Effective From"
                  rules="requiredDate"
                  v-slot="{ errors }"
                  class="col-sm-6"
                >
                  <b-form-input
                    :min="getTodayDate()"
                    id="startDate"
                    v-model="start_date"
                    type="date"
                    onkeydown="return false"
                  ></b-form-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  name="StartTime"
                  rules="required"
                  v-slot="{ errors }"
                  class="col-sm-3"
                >
                  <vue-timepicker id="startTime" v-model="start_time" format="HH:mm"></vue-timepicker>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>
              <CRow class="mt-3">
                <CCol tag="label" sm="3" class="col-form-label">{{ $t("PROMOS.LABELS.TO") }}</CCol>
                <ValidationProvider
                  name="Effective To"
                  rules="requiredDate"
                  v-slot="{ errors }"
                  class="col-sm-6"
                >
                  <b-form-input
                    :min="getTodayDate()"
                    id="endDate"
                    v-model="end_date"
                    type="date"
                    onkeydown="return false"
                  ></b-form-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  name="EndTime"
                  rules="required"
                  v-slot="{ errors }"
                  class="col-sm-3"
                >
                  <vue-timepicker id="endTime" v-model="end_time" format="HH:mm"></vue-timepicker>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </CRow>
            </CForm>
          </ValidationObserver>
        </CCardBody>
        <CCardFooter>
          <CRow class="float-left">
            <CCol sm class="text-center">
              <CButton @click.prevent="validatePromo" color="durianprimary">
                <CIcon name="cil-check-circle" />
                &nbsp;{{ $t("PROMOS.SUBMIT") }}
              </CButton>
              <CButton @click="goBack">{{ $t("COMMON.BACK_BUTTON") }}</CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
    <CModal
      :title="$t('PROMOS.SETTINGS')"
      color="light"
      size="lg"
      :no-close-on-backdrop="false"
      :centered="true"
      :show="showSettingsModal"
    >
      <CContainer>
        <ValidationObserver ref="validationObserverModal">
          <CForm>
            <CRow>
              <CCol>
                <ValidationProvider name="Promo" rules="requiredPromoType" v-slot="{ errors }">
                  <multiselect
                    v-model="type"
                    :options="promo_types"
                    :multiple="false"
                    class="mx-3"
                    :placeholder="$t('PROMOS.TYPES.SELECT')"
                    track-by="type"
                    label="label"
                    :allow-empty="false"
                    @select="updatePromoSettings"
                  ></multiselect>
                  <span class="text-danger mx-3">{{ errors[0] }}</span>
                </ValidationProvider>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CContainer>
      <template #footer>
        <CButton @click="checkPromoType" color="primary">{{ $t("COMMON.NEXT") }}</CButton>
      </template>
    </CModal>
    <CModal
      :title="$t('PROMOS.CREATE_CONFIRMATION_TITLE')"
      color="light"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="showConfirmationModal"
    >
      <CContainer>
        <label class="modal-msg">{{ $t("PROMOS.CREATE_CONFIRMATION_MSG")}}</label>
      </CContainer>
      <template #footer footer-with-border>
        <CButton @click.prevent="formatPromoData()" color="primary">{{ $t("COMMON.CONTINUE") }}</CButton>
        <CButton
          @click="showConfirmationModal = false"
          color="lightborder"
        >{{ $t("COMMON.CANCEL") }}</CButton>
      </template>
    </CModal>

    <CModal
      :title="$t('PROMOS.DUPLICATE_ALERT_TITLE')"
      color="danger"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="showDuplicatePromoAlert"
    >
      <CContainer>
        <label class="modal-msg">{{ $t("PROMOS.DUPLICATE_PROMO_ALERT")}}</label>
      </CContainer>
      <template #footer>
        <CButton @click="clearPromoLabel" color="durianprimary">{{ $t("COMMON.OK") }}</CButton>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import PromoBuilder from "./../../models/builder/promoBuilder.js";
import "./../../validations/validationRules.js";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { createPromo, searchPromo } from "@/api/card_promo.api.js";
import Multiselect from "vue-multiselect";
import { listPaymentMethods } from "@/api/paymentMethods.api.js";

export default {
  name: "NewPromo",
  components: {
    VueTimepicker,
    Multiselect
  },
  data() {
    return {
      paymentMethods: [],
      promo_types: [],
      promo: {},
      options: [
        { name: "Card issued by Certain bank", id: 2 },
        { name: "Specific BIN", id: 3 }
      ],
      promoChannel: [],
      banks: ["BCA", "BNI", "BRI", "MANDIRI", "PERMATA"],
      selectedBank: "",
      start_date: "",
      start_time: "00:00",
      end_date: "",
      end_time: "00:00",
      BINNumbers: "",
      showSettingsModal: true,
      showConfirmationModal: false,
      type: "",
      showPromoChannel: false,
      showPayMethodsVA: false,
      showPayMethodsEWallet: false,
      deduction_type: ["total_price"],
      sub_type_options: [],
      limit_type_options: [],
      disable_limit: false,
      disable_subtype: false,
      selectedPaymentMethods: [],
      showDuplicatePromoAlert: false
    };
  },
  methods: {
    checkPromoType() {
      this.$refs.validationObserverModal.validate().then(result => {
        if (result) {
          this.showSettingsModal = false;
        }
      });
    },
    updatePromoSettings(val) {
      this.promo.type = val.type;
      this.setDefaultValidations();
    },
    setDefaultValidations() {
      if (this.promo.type != "") {
        switch (this.promo.type) {
          case "card_promos":
            this.showPromoChannel = true;
            this.promo.sub_type = this.getSubTypeByValue("direct_discount");
            this.promo.limit_type = this.getLimitByValue("quota");
            this.disable_limit = true;
            this.disable_subtype = true;
            this.showPayMethodsVA = false;
            this.showPayMethodsEWallet = false;
            break;
          case "va_promos":
            this.showPromoChannel = false;
            this.promo.sub_type = this.getSubTypeByValue("direct_discount");
            this.disable_subtype = true;
            this.promo.limit_type = null;
            this.showPayMethodsVA = true;
            this.showPayMethodsEWallet = false;
            this.disable_limit = false;
            break;
          case "ewallet_promos":
            this.showPromoChannel = false;
            this.promo.sub_type = "";
            this.promo.limit_type = null;
            this.disable_subtype = false;
            this.showPayMethodsVA = false;
            this.showPayMethodsEWallet = true;
            this.disable_limit = false;
            break;
          default:
            console.error("invalid promo type");
        }
      }
    },
    async validatePromo() {
      await this.$refs.validationObserver.validate().then(result => {
        if (result) {
          this.promo.starts_at = this.getISODate(
            this.start_date,
            this.start_time
          );
          this.promo.ends_at = this.getISODate(this.end_date, this.end_time);
          if (this.promo.starts_at > this.promo.ends_at) {
            this.showToaster("start date should be less than end date");
            return;
          }
          this.showConfirmationModal = true;
        }
      });
    },
    formatPromoData() {
      this.promo.code = this.promo.label;
      this.promo.starts_at = this.getISODate(this.start_date, this.start_time);
      this.promo.ends_at = this.getISODate(this.end_date, this.end_time);
      this.promo.sub_type = this.promo.sub_type.value;
      this.promo.limit_type = this.promo.limit_type.value;
      if (this.promo.type == "card_promos") {
        this.promo.promo_details.bin_list = this.getBINList();
        this.promo.promo_details.bank_codes = this.getBankCodes();
      } else if (this.promo.type == "va_promos") {
        let promo_details = {};
        promo_details.bank_codes = this.getUniquePayIDs();
        this.promo.promo_details = promo_details;
      } else if (this.promo.type == "ewallet_promos") {
        let promo_details = {};
        promo_details.ewallet_codes = this.getUniquePayIDs();
        this.promo.promo_details = promo_details;
      }
      this.addPromo();
    },
    getUniquePayIDs() {
      let payIDs = [];
      this.selectedPaymentMethods.forEach(element => {
        payIDs.push(element.unique_id);
      });
      return payIDs;
    },
    addPromo() {
      createPromo(this.promo)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.showSuccessToaster("Added Successfully");
            this.$router.push("/promos");
          }
        })
        .catch(e => {
          this.handleAPIError(e);
        });
    },
    getISODate(date, time) {
      date = this.moment(date, "YYYY-MM-DD");
      var input = time.split(":");
      let hour = parseInt(input[0]);
      let min = parseInt(input[1]);
      let date_time = new Date(
        parseInt(date.format("YYYY")),
        parseInt(date.format("MM")) - 1,
        parseInt(date.format("DD")),
        hour,
        min,
        0,
        0
      );
      return date_time.toISOString();
    },
    getBankCodes() {
      let bank_codes = [];
      if (this.selectedBank !== "") {
        bank_codes.push(this.selectedBank);
      }
      return bank_codes;
    },
    getBINList() {
      let bin_list = [];
      let value = this.BINNumbers.trim();
      if (this.BINNumbers !== "") {
        if (value.includes(",")) {
          let nums = value.split(",");
          for (let index = 0; index < nums.length; index++) {
            bin_list.push(parseInt(nums[index]));
          }
        } else {
          bin_list.push(parseInt(value));
        }
      }
      return bin_list;
    },
    getPayMethods() {
      listPaymentMethods()
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.paymentMethods = this.formatPaymentMethods(
              response.data.data.payment_methods
            );
          }
        })
        .catch(this.handleAPIError);
    },
    formatPaymentMethods(data) {
      var groupBy = function(array, key) {
        return array.reduce(function(result, pay_method) {
          (result[pay_method[key]] = result[pay_method[key]] || []).push(
            pay_method
          );
          return result;
        }, {});
      };

      return groupBy(data, "category");
    },
    getPaymentMethodsByCategory() {
      if (this.promo.type != "") {
        let category = "";
        switch (this.promo.type) {
          case "card_promos":
            category = "CARD";
            break;
          case "va_promos":
            category = "VA";
            break;
          case "ewallet_promos":
            category = "EWALLET";
            break;
        }
        return this.paymentMethods[category];
      } else {
        return [];
      }
    },
    setLimitType(limit) {
      if (this.promo.type != "card_promos") {
        this.promo.limit_type = limit;
        if (limit != null) {
          this.disable_limit = true;
        } else {
          this.disable_limit = false;
        }
      }
    },
    updatePaymentMethods() {},
    verifyPromoLabel(label) {
      if (label != "") {
        searchPromo(label)
          .then(response => {
            if (response.data.data) {
              this.showDuplicatePromoAlert = true;
            }
          })
          .catch(this.handleAPIError);
      }
    },
    clearPromoLabel() {
      this.promo.label = "";
      this.showDuplicatePromoAlert = false;
    },
    initialize() {
      this.promo = new PromoBuilder().build();
      this.sub_type_options = this.getPromoSubTypes();
      this.limit_type_options = this.getPromoLimitTypes();
      this.promo_types = this.getPromoTypes();
      this.promo.currency = "IDR";
      this.promo.price_deduction_type = "total_price";
      this.getPayMethods();
    },
    getLimitByValue(value) {
      let limit = null;
      this.limit_type_options.forEach(element => {
        if (element.value == value) {
          limit = element;
        }
      });
      return limit;
    },
    getSubTypeByValue(value) {
      let subtype = null;
      this.sub_type_options.forEach(element => {
        if (element.value == value) {
          subtype = element;
        }
      });
      return subtype;
    }
  },
  computed: {
    showBINList() {
      if (this.promoChannel.includes(3)) {
        return true;
      }
      return false;
    },
    showBankList() {
      if (this.promoChannel.includes(2)) {
        return true;
      }
      return false;
    },
    showPercentageDiscount() {
      if (this.promo.discount_type == "percentage") {
        this.setLimitType(this.getLimitByValue("quota"));
        return true;
      }
      return false;
    },
    showAmountDiscount() {
      if (this.promo.discount_type == "flat") {
        this.setLimitType(null);
        return true;
      }
      return false;
    }
  },
  created() {
    this.initialize();
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
.col-sm-8 {
  padding-top: 10px;
}
</style>
